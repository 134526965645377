import Vuex from 'vuex';
import * as user from './modules/UserStore';
import * as presenter from './modules/PresenterStore';
import * as groups from './modules/Groups';
import * as organizations from './modules/Organizations';
import * as events from './modules/Events';
import * as syncMachine from './modules/SyncMachine';

export default Vuex.createStore({
	modules: {
		user,
		presenter,
		groups,
		organizations,
		events,
		syncMachine
	},
	state: {
		sideBarOpen: false
	},
	getters: {
		sideBarOpen: state => {
			return state.sideBarOpen
		}
	},
	mutations: {
		toggleSidebar(state) {
			state.sideBarOpen = !state.sideBarOpen
		}
	},
	actions: {
		toggleSidebar(context) {
			context.commit('toggleSidebar')
		}
	}
});
