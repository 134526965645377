<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>
export default {
	name: 'app',
	beforeCreate() {
		this.$store.dispatch('user/isAuthenticated').then((response) => {
			if (response === true) {
				//this.$store.dispatch('syncMachine/checkIfSyncStation');
			}
		});
	}
}
</script>
<style lang="scss"></style>
