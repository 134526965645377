import eventService from "@/service/EventService";

export const namespaced = true;

export const state = {
    currentEventsArray: [],
    workingEvent: {groupsID: '', organizationsID: '', event_title: '', event_start_date: '', event_end_date: '', event_location:'', show_disclosure: 0, event_disclosure_text: '', show_record_auth: 0, record_auth_text: '', show_consent_check: 0, consent_text: '', show_number_label: 0, number_label_text: ''},
    workingRoom: {}
};

export const getters = {
    currentEventsArray: state=>{
        return state.currentEventsArray;
    },
    workingEvent: state => {
        return state.workingEvent;
    }
};

export const mutations = {
    setCurrentEventsArray(state, eventData){
        state.currentEventsArray = eventData
    },
    addEventToArray(state, event){
        state.currentEventsArray.push(event);
    },
    setWorkingEvent(state, event){
        state.workingEvent = event;
    },
    updateEventArray(state, event){
        for(let i in state.currentEventsArray){
            if(state.currentEventsArray[i].eventsID === event.eventsID){
                state.currentEventsArray[i] = event;
            }
        }
    },
    updateEventRoom(state, eventRoom){
        for(let i in state.currentEventsArray){
            for(let j in state.currentEventsArray[i].rooms){
                if(state.currentEventsArray[i].rooms[j].event_roomsID == eventRoom.event_roomsID){
                    state.currentEventsArray[i].rooms[j] = eventRoom;
                    break;
                }
            }
        }
    },
    addRoomToEvent(state, payload){
        for(let i in state.currentEventsArray){
            if(state.currentEventsArray[i].eventsID == payload.eventsID){
                if(Array.isArray(state.currentEventsArray[i].rooms)){
                    state.currentEventsArray[i].rooms.push(payload.newRoom);
                }
                else{
                    state.currentEventsArray[i].rooms = [payload.newRoom];
                }
                break;
            }
        }
    },
    removeRoomFromEvent(state, payload){
        for(let i in state.currentEventsArray){
            if(state.currentEventsArray[i].eventsID == payload.eventsID){
                for(let j in state.currentEventsArray.rooms){
                    if(state.currentEventsArray[i].rooms[j].event_roomsID == payload.event_roomsID){
                        state.currentEventsArray[i].rooms.splice(j, 1);
                    }
                    break;
                }
            }
        }
    },

	updateEventStation(state, syncStation){
		for(let i in state.currentEventsArray){
			for(let j in state.currentEventsArray[i].syncStations){
				if(state.currentEventsArray[i].syncStations[j].sync_stationsID == syncStation.sync_stationsID){
					state.currentEventsArray[i].syncStations[j] = syncStation;
					break;
				}
			}
		}
	},
	addStationToEvent(state, payload){
		for(let i in state.currentEventsArray){
			if(state.currentEventsArray[i].eventsID == payload.eventsID){
				if(Array.isArray(state.currentEventsArray[i].syncStations)){
					state.currentEventsArray[i].syncStations.push(payload.newStation);
				}
				else{
					state.currentEventsArray[i].syncStations = [payload.newStation];
				}
				break;
			}
		}
	},
	removeStationFromEvent(state, payload){
		for(let i in state.currentEventsArray){
			if(state.currentEventsArray[i].eventsID == payload.eventsID){
				for(let j in state.currentEventsArray.syncStations){
					if(state.currentEventsArray[i].syncStations[j].sync_stationsID == payload.sync_stationsID){
						state.currentEventsArray[i].syncStations.splice(j, 1);
					}
					break;
				}
			}
		}
	},
    setWorkingRoom(state, room){
        state.workingRoom = room;
    }
};

export const actions = {
    setEventsData({commit}){
        return new Promise((resolve, reject) => {
            eventService.getAll().then((response) => {
                if (response.data.status === 200){
                    commit('setCurrentEventsArray', response.data.record);
                }
                else{
                    reject();
                }
            }, (error) => {
                console.log(error);
                reject();
            });
        });
    },
    addEventToArray({commit}, payload){
        commit('addEventToArray', payload);
    },
    setWorkingEvent({commit}, workingEvent){
		commit('setWorkingEvent', workingEvent);
    },
    updateEventArray({commit}, updatedEvent){
        commit('updateEventArray', updatedEvent);
    },
    updateEventRoom({commit}, eventRoom){
        commit('updateEventRoom', eventRoom);
    },
    /**
     * @param commit
     * @param payload.eventsID: int
     * @param payload.newRoom: obj
     */
    addRoomToEvent({commit}, payload){
        commit('addRoomToEvent', payload);
    },
    /**
     * @param commit
     * @param payload.eventsID: int
     * @param payload.event_roomsID: int
     */
    removeRoom({commit}, payload){
        commit('removeRoomFromEvent', payload);
    },
    /**
     * @param commit
     * @param event_roomsID: obj
     */
    setWorkingRoom({commit}, room){
        commit('setWorkingRoom', room);
    },

	addStationToEvent({commit}, payload){
		commit('addStationToEvent', payload);
	},
	updateEventStation({commit}, eventRoom){
		commit('updateEventStation', eventRoom);
	},
	removeStation({commit}, payload){
		commit('removeStationFromEvent', payload);
	},


};
