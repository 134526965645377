<template>
    <div id="organizationsView">
        <div class="card mb-3">
            <div class="card-header">
                <h4>Create Organization</h4>
            </div>
            <div class="card-body">
                <form @submit.prevent="createNewOrganization">
                    <div class="mb-3">
                        <label class="form-label">Organization Name</label>
                        <input type="text" class="form-control" v-model="newOrganization.organization_name" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Organization Acronym</label>
                        <input type="text" class="form-control" v-model="newOrganization.organization_acronym" />
                    </div>
                    <div class="mb-3">
                        <input class="btn btn-success" type="submit" value="submit" />
                    </div>
                </form>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div v-if="organizationsArray">
                    <table class="table">
                        <thead>
                            <tr><th>Organization Name</th><th>Acronym</th></tr>
                        </thead>
                        <tbody>
                            <tr v-for="organization in organizationsArray" :key="organization.organizationsID">
                                <td>{{ organization.organization_acronym }}</td>
                                <td>{{ organization.organization_name }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else>
                    <p class="alert alert-warning">
                        No organization data to show.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrganizationsView",
		title: 'SYNC Organizations',
        data(){
            return {
                newOrganization: {organization_name: '', organization_acronym: ''},
            }
        },
        methods: {
            createNewOrganization(){
                if(this.newOrganization.organization_acronym !== ''){
                    this.axios.post('/Organization', this.newOrganization).then((response) => {
                        if(response.data.status==200){
                            this.$store.dispatch('organizations/addOrganizationToArray', response.data.record);
                        }
                        }, (error) => {
                            console.log(error);
                    });
                }
            }
        },
        computed: {
            organizationsArray(){
                return this.$store.getters['organizations/organizationsArray']
            }
        },
        mounted(){
            this.$store.dispatch('organizations/setOrganizationsData');
        }
    }
</script>

<style scoped>

</style>
