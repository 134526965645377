<template>
	<section class="vh-100">
		<div class="container py-5 h-100">
			<div class="row d-flex justify-content-center align-items-center h-100">
				<div class="col-12 col-md-8 col-lg-6 col-xl-5">
					<div class="text-center mb-3"></div>
					<div class="card bg-dark text-white" style="border-radius: 1rem;">
						<div class="card-body p-5 text-center">
							<img src="https://meetingfiles.com/assets/images/sync-logo-white.png" width="150"/>
							<div class="mb-md-5 mt-md-4 pb-5">

								<p v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</p>

								<transition>
									<div id="loginFormContainer" v-if="!loggedIn">
										<form id="loginForm" action="" method="" @submit.prevent="submitLogin" :disabled="submitting">
											<fieldset :disabled="loading">
												<div class="mb-3">
													<label>User Name</label>
													<input type="text" class="form-control" v-model="username" name="username"/>
												</div>
												<div class="mb-3">
													<label>Password</label>
													<input type="password" class="form-control" v-model="password" name="password"/>
												</div>
												<div class="mt-3 d-grid gap-2" v-if="!submitting">
													<input class="btn btn-outline-light btn-lg px-5" type="submit" value="submit"/>
												</div>
												<div class="mt-3 d-grid gap-2" v-if="submitting">
													<Loader/>
												</div>
											</fieldset>
										</form>
									</div>
								</transition>

								<transition>
									<div id="stationContainer" v-if="showEventForm">
										<form id="stationForm" @submit.prevent="submitStation">
											<div class="mb-3">
												<label>Select this Sync Stations Name:</label>
												<select name="sync_stationsID" class="form-select">
													<option value="">Select Sync Station Name</option>
												</select>
											</div>
											<p class="text-center">
												<em>If there are no sync station names please log out and contact your admin to create one in the admin panel.</em>
											</p>
										</form>
									</div>
								</transition>
							</div>

							<div>
								<!--<p class="mb-0">Meeting Files SYNC! is the premier software to manage your presentation files for all of your events and meetings.</p>-->
								<p class="mb-0">Download the Desktop App <a href="https://sync.meetingfiles.com/desktop">Here</a></p>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>


<script>
import Loader from "@/components/Loader";

export default {
	name: "Home",
	data: function () {
		return {
			username: '',
			password: '',
			errorMessage: false,
			loggedIn: false,
			showEventForm: false,
			userType: null,
			submitting: false

		};
	},
	components: {
		Loader
	},
	methods: {
		submitLogin() {
			this.submitting = true;
			this.$store.dispatch('user/authenticateUser', {
				username: this.username,
				password: this.password
			}).then(() => {
				//let userLevel = this.$store.getters['user/userLevel'];
				this.$router.push("/app/");
				/*if (userLevel === 1) {
				} else {
					this.loggedIn = true;
					this.showEventForm = true;
				}*/
				this.submitting = false;
			}, () => {
				this.submitting = false;
				this.errorMessage = this.$store.getters['user/authenticationErrorMessage'];
			});
		},
		submitStation() {
			this.$store.dispatch('user/authenticateUser', {
				username: this.username,
				password: this.password
			}).then(() => {
				this.$router.push("/app/");
			}, () => {
				this.errorMessage = this.$store.getters['user/authenticationErrorMessage'];
			});
		}

	},
	mounted() {
		this.$store.dispatch('user/isAuthenticated').then(() => {
			this.$router.push("/app/");
		});
	}
}
</script>

<style scoped lang="scss">
.side-link-container {
	background-color: #c0c0c0;
}

.login-card {
	margin: auto;
}
</style>
