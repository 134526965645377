<template>
    <div id="usersAdminView">
		<h5 class="alert alert-info">Users Admin</h5>
		<div class="row">
			<div class="col-md-6">
				<div class="card mb-5">
					<div class="card-header">
						<h4>Add a User</h4>
					</div>
					<div class="card-body">
						<form @submit.prevent="submitNewUser">
							<div class="mb-3">
								<label class="form-label">First Name</label>
								<input type="text" class="form-control" v-model="newUser.user_fname" />
							</div>
							<div class="mb-3">
								<label class="form-label">Last Name</label>
								<input type="text" class="form-control" v-model="newUser.user_lname" />
							</div>
							<div class="mb-3">
								<label class="form-label">Email</label>
								<input type="text" class="form-control" v-model="newUser.user_email" />
							</div>
							<div class="mb-3">
								<label class="form-label">Username</label>
								<input type="text" class="form-control" v-model="newUser.user_username" />
							</div>
							<div class="mb-3">
								<label class="form-label">Password</label>
								<input type="text" class="form-control" v-model="newUser.user_ace" />
							</div>
							<div class="mb-3">
								<label class="form-label">Access Level</label>
								<select class="form-select" v-model="newUser.user_access_level">
									<option value="2">Group Admin</option>
									<option value="3" selected>Group Viewer</option>
								</select>
							</div>
							<div class="mb-3">
								<label class="form-label">Group</label>
								<select class="form-select" v-model="newUser.groupsID" >
									<option value="" selected>Select a Group to Assign User To</option>
									<option v-for="group in groupsArray" :key="group.user_groupsID" :value="group.groupsID">{{ group.group_name }}</option>
								</select>
							</div>
							<div class="mb-3">
								<input type="submit" value="submit" class="btn btn-success" />
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="card">
					<div class="card-header">
						<h4>Users</h4>
					</div>
					<div class="card-body">
						<div v-if="groupsArray">
							<table class="table mb-3" v-for="group in groupsArray" :key="group.groupsID">
								<thead><th colspan="4">{{group.group_name}}</th></thead>
								<tbody v-if="group.users">
								<tr v-for="user in group.users" :key="user.usersID">
									<td>{{user.user_fname}} {{user.user_lname}}</td>
									<td>{{user.user_username}}</td>
									<td>{{user.user_email}}</td>
									<td><a :href="'https://sync.meetingfiles.com/cp/?uuid=' + user.uuid ">Set Password</a></td>
								</tr>
								</tbody>
							</table>
						</div>
						<div v-else>
							<p class="alert alert-warning">
								No user data to show.
							</p>
						</div>
					</div>
				</div>
			</div>

		</div>
    </div>
</template>

<script>
    export default {
        name: "UsersAdminView",
		title: 'SYNC Users',
        data(){
            return{
                newUser:{user_fname: '',user_lname: '',user_email: '',user_username: '',user_ace: '',user_access_level: 3, groupsID: '',}
            }
        },
        methods: {
            submitNewUser(){
                if(this.validateForm()){
                    this.axios.post('/User', this.newUser).then((response)=>{
                        if(response.data.status == 200){
                            this.$store.dispatch('groups/addUserToGroup', response.data.record);
                            this.clearForm();
                        }
                    });
                }
            },
            clearForm(){
                for (let key in this.newUser){
                    if (typeof this.newUser[key] === 'string'){
                        this.newUser[key] = undefined;
                    }
                }
            },
            validateForm(){
                let validForm = true;
                for (let key in this.newUser){
                    if(this.newUser[key] == undefined || this.newUser[key] == ''){
                        validForm = false;
                    }
                }
                return validForm;
            }
        },
        computed: {
            groupsArray(){
                return this.$store.getters['groups/getGroupsArray'];
            },
        },
        mounted(){
            this.axios.get('/User').then((response)=>{
               if(response.data.status == ''){
                   this.usersArray = response.data.record;
               }
            });
            if(!this.$store.getters['groups/groupsArray']){
                this.$store.dispatch('groups/setGroupsData').then(() => {
                    console.log('we set the groups');
                })
            }
        }
    }
</script>

<style scoped>

</style>
