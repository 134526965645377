<template>
    <div id="groupsAdminView">
        <h2>Groups Admin</h2>
        <div class="card">
            <div class="card-header">
                <h4>Add a Group</h4>
            </div>
            <div class="card-body">
                <form @submit.prevent="submitNewGroup">
                    <div class="mb-3">
                        <label class="form-label">Group Name</label>
                        <input type="text" class="form-control" v-model="newGroupData.group_name" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Group Address</label>
                        <input type="text" class="form-control" v-model="newGroupData.group_address" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Group City</label>
                        <input type="text" class="form-control" v-model="newGroupData.group_city" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Group State</label>
                        <input type="text" class="form-control" v-model="newGroupData.group_state" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Group Zip</label>
                        <input type="text" class="form-control" v-model="newGroupData.group_zip" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Group Country</label>
                        <input type="text" class="form-control" v-model="newGroupData.group_country" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Group Phone</label>
                        <input type="text" class="form-control" v-model="newGroupData.group_phone" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Group Website</label>
                        <input type="text" class="form-control" v-model="newGroupData.group_website" />
                    </div>
                    <div class="mb-3">
                        <input type="submit" class="btn btn-success" value="submit" />
                    </div>
                </form>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div v-if="groupsArray">
                    <table class="table">
                        <thead>
                        <tr><th>ID</th><th>Name</th><th>Status</th><th>Address</th><th>Phone</th><th>&nbsp;</th></tr>
                        </thead>
                        <tbody>
                        <tr v-for="group in groupsArray" :key="group.groupsID">
                            <td>{{group.groupsID}}</td>
                            <td>{{group.group_name}}</td>
                            <td>{{group.group_status}}</td>
                            <td>{{group.group_address}}<br>{{group.group_city}} {{group.group_state}}</td>
                            <td>{{group.group_phone}}</td>
                            <td><button class="btn btn-secondary">view & edit</button></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else>
                    <p class="alert alert-warning">
                        No group data to show.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "GroupsAdminView",
		title: 'SYNC Groups',
        data(){
            return{
                newGroupData: {group_name:'', group_address:'', group_city:'', group_state:'', group_zip:'', group_country:'', group_phone:'', group_website:''}
            }
        },
        methods: {
            submitNewGroup(){
                if(this.newGroupData.group_name !== ''){
                    this.axios.post('/Group', this.newGroupData).then((response)=>{
                        if(response.data.status == 200){
                            this.$store.dispatch('groups/addGroupToArray', response.data.record);
                            this.clearForm();
                        }
                        else{
                            console.log('show alert, no go');
                        }
                    });
                }
                else{
                    console.log("no groupname");
                }

            },
            clearForm(){
                for (let key in this.newGroupData){
                    if (typeof this.newGroupData[key] === 'string'){
                        this.newGroupData[key] = undefined;
                    } else if (this.newGroupData[key] instanceof Array) {
                        this.newGroupData[key] = [];
                    } // else ???  Not sure how you want to handle other types
                }
            }
        },
        computed: {
            groupsArray(){
                return this.$store.getters['groups/getGroupsArray'];
            }
        },
        mounted() {
            if(!this.$store.getters['groups/getGroupsArray']){
                this.$store.dispatch('groups/setGroupsData').then(() => {
                    //success
                }, () => {
                    //error
                });
            }
        }
    }
</script>

<style scoped>

</style>
