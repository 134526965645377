<template>
    <nav id="sidebarMenu" class="col-md-2 col-lg-1 d-md-block bg-dark sidebar collapse vh-100 border-end border-1 border-secondary">
        <div class="position-sticky pt-3">
            <ul class="nav flex-column">
                <li class="nav-item">
                    <router-link :to="{ name: 'DashboardHome', params: {}}" class="nav-link">Dashboard</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'PresenterView', params: {}}" class="nav-link">Presenters</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'EventsView', params: {}}" class="nav-link">Events</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'OrganizationsView', params: {}}" class="nav-link">Organizations</router-link>
                </li>

            </ul>
            <ul v-if="userIsAdmin">
                <li class="nav-item">
                    <router-link :to="{ name: 'GroupsAdminView', params: {}}" class="nav-link">Groups</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'UsersAdminView', params: {}}" class="nav-link">Users</router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    import {mapState} from 'vuex'

    export default {
        name: 'Sidebar',
        computed: {
            ...mapState(['sideBarOpen']),
            userIsAdmin(){
                return this.$store.getters['user/userLevel']===1 ? true : false;
            }
        },
        methods: {
            exitApp(){
                console.log(document.cookie);
                document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                this.$store.commit('setAuthentication', false);
                this.$router.push("/");
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
