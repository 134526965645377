<template>
	<div>
		<div v-if="workingPresenter">
			<div id="presenterSchedule">
				<div class="card" v-if="workingPresenter">
					<div class="card-header">
						<h4>Presenter Schedule for: {{ workingPresenter.presenters_fname }} {{ workingPresenter.presenters_lname }}</h4>
					</div>
					<div class="card-body">
						<div v-if="currentPresentationSchedule">
							<table class="table">
								<tbody>
								<tr v-for="row in currentPresentationSchedule" :key="row.presenter_scheduleID">
									<td>{{ row.event.event_title }}<br>{{ row.room.room_name }}</td>
									<td>{{ row.presentation_title }}</td>
									<td>{{ row.presentation_date }}</td>
									<td>{{ row.presentation_time }}</td>
									<td class="">
										<div class="btn-group">
                      <!-- Edit Action -->
											<button type="button" class="btn btn-secondary" @click="editSchedule(row)">
												<i class="bi bi-pencil-square"></i> edit
											</button>
                      <!-- Goto Sync Action -->
											<button type="button" class="btn btn-secondary" @click.prevent.stop="goToSyncUploadPage(row)">
												<img src="@/assets/images/sync-icon-white.png" width="16"/> sync
											</button>
                      <!-- View In Schedule Action
                      <router-link :to="{ name: 'EventRoomSchedule', params: {ID: row.room.event_roomsID, presenter_scheduleID: row.presenter_scheduleID}}" class="btn btn-secondary"><i class="bi bi-calendar-date"></i> View Schedule</router-link>
                      -->
                      <router-link :to="{ name: 'EventRoomSchedule', params: {ID: row.room.event_roomsID}, query: {presenter_scheduleID: row.presentation_scheduleID}}" class="btn btn-secondary"><i class="bi bi-calendar-date"></i> View Schedule</router-link>

                      <!-- Download Action -->
                      <!--<button type="button" class="btn btn-secondary" @click.prevent.stop="download(row)">
                        <i class="bi bi-pencil-square"></i> download
                      </button>-->
										</div>
									</td>
								</tr>
								</tbody>
							</table>
						</div>
						<div v-else>
							<p class="alert alert-danger">
								No current presentations for this presenter. You can check archives here...
							</p>
						</div>
					</div>
				</div>
				<h4 v-else class="alert alert-danger">No Presenter Set, Please go back and select a presenter to view their schedule.</h4>
			</div>

			<div class="card mt-3">
				<div class="card-header">

					<h4 v-if="!isEditing">Add Presentation for {{ workingPresenter.presenters_fname }} {{ workingPresenter.presenters_lname }}</h4>
					<h4 v-else class="alert alert-danger">Editing Schedule Entry</h4>
				</div>
				<div class="card-body">
					<form @submit.prevent="addNewPresentation">

						<div class="mb-3">
							<label class="form-label">Select the Event</label>
							<select v-model="newPresentation.eventsID" @change="selectEvent" class="form-select">
								<option value="">Select an Event</option>
								<option v-for="event in myGroupsEvents" :key="event.eventsID" :value="event.eventsID">{{ event.event_title }}</option>
							</select>
						</div>
						<div class="mb-3" v-if="workingEvent">
							<label class="form-label">Event Room</label>
							<select v-model="newPresentation.event_roomsID" class="form-select" @change="setRoomValue()">
								<option v-for="room in workingEvent.rooms" :key="room.event_roomsID" :value="room.event_roomsID">{{ room.room_name }}</option>
							</select>
						</div>

						<div class="mb-3">
							<label class="form-label">Presentation Type</label>
							<select class="form-select" v-model="newPresentation.presentation_typesID">
								<option value="1">Oral Presentation</option>
								<option value="2">PDF Presentation</option>
								<option value="3">Poster Presentation</option>
								<option value="4">Video Presentation</option>
							</select>
						</div>
						<div class="mb-3">
							<label class="form-label">Presentation Title</label>
							<input type="text" class="form-control" v-model="newPresentation.presentation_title"/>
						</div>
						<div class="mb-3">
							<label class="form-label">Presentation Date</label>
							<input type="date" class="form-control" v-model="newPresentation.presentation_date" v-bind:min="minDate" v-bind:max="maxDate"/>
						</div>
						<div class="mb-3">
							<label class="form-label">Presentation Time</label>
							<input type="time" class="form-control" v-model="newPresentation.presentation_time"/>
						</div>
						<div class="mb-3">
							<input type="submit" class="btn btn-success" value="submit"/>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div v-else>
			<p class="alert alert-danger">Could not set the presenter to work with. Please go back and try again...</p>
		</div>
	</div>
</template>

<script>
import eventService from "@/service/EventService";

export default {
	name: "PresenterSchedule",
	title: 'SYNC Presenter Schedule',
	data() {
		return {
			presentersID: null,
			workingPresenter: null,
			currentPresentationSchedule: [],
			newPresentation: {
				eventsID: '',
				event_roomsID: '',
				presentation_typesID: 1,
				presentation_title: '',
				presentation_date: null,
				presentation_time: null
			},
			workingEvent: null,
			myGroupsEvents: null,
			isEditing: false
		}
	},
	methods: {
		setPresenter: function () {
			this.axios.get('/Presenter/' + this.presentersID).then((response) => {
				if (response.data.status == 200) {
					// eslint-disable-next-line no-mixed-spaces-and-tabs
					this.workingPresenter = response.data.record;
					this.setPresenterSchedule();
					this.setMyGroupsEvents();
				}
			}, (error) => {
				console.log(error);
			});
		},
		setPresenterSchedule() {
			let params = {method: 'getCurrentPresenterSchedule', presentersID: this.presentersID};

			this.axios.get('/PresentationSchedule', {params: params}).then((response) => {
				if (response.status == 200) {
					this.currentPresentationSchedule = response.data.record;
				}

			});
		},
		setMyGroupsEvents() {
			eventService.getAll().then((response) => {
				if (response.data.status === 200) {
					this.myGroupsEvents = response.data.record;
				}
			}, (error) => {
				console.log(error);
			});
		},
		selectEvent() {
			for (let i in this.myGroupsEvents) {
				if (this.myGroupsEvents[i].eventsID === this.newPresentation.eventsID) {
					this.workingEvent = this.myGroupsEvents[i];
					this.newPresentation.presentation_date = this.workingEvent.event_start_date
					this.newPresentation.event_roomsID = this.workingEvent.rooms[0].event_roomsID;
				}
			}
		},
		addNewPresentation() {
			this.newPresentation.presentersID = this.workingPresenter.presentersID;
			if (this.isEditing) {
				this.updateSchedule();
			}
			else {
				this.axios.post('/PresentationSchedule', this.newPresentation).then((response) => {
					if (response.status == 200) {
						if (this.currentPresentationSchedule) {
							this.currentPresentationSchedule.push(response.data.record);
						} else {
							this.currentPresentationSchedule = [response.data.record];
						}
						this.clearNewPresentationForm();
					} else {
						alert("Could not record new presentation\n" + response.message);
					}
				});
			}
		},
		clearNewPresentationForm() {
			for (let key in this.newPresentation) {
				if (typeof this.newPresentation[key] === 'string') {
					this.newPresentation[key] = undefined;
				}
			}
			this.isEditing = false;
		},
		goToSyncUploadPage(presentation) {
			let url = encodeURI('https://sync.meetingfiles.com/my-sync/auth.php?anyscreen=true&uuid=' + this.workingPresenter.uuid + '&pid=' + presentation.presentation_scheduleID);
			window.open(url, "_blank");
		},
		editSchedule(scheduleRow) {
			this.isEditing = true;
			this.newPresentation.eventsID = scheduleRow.eventsID;
			this.newPresentation.presentation_title = scheduleRow.presentation_title;
			this.newPresentation.presentation_date = scheduleRow.presentation_date;
			this.newPresentation.presentation_time = scheduleRow.presentation_time;
			this.newPresentation.ID = scheduleRow.presentation_scheduleID;

			for (let i in this.myGroupsEvents) {
				if (this.myGroupsEvents[i].eventsID === this.newPresentation.eventsID) {
					this.workingEvent = this.myGroupsEvents[i];
					this.newPresentation.presentation_date = this.workingEvent.event_start_date
					this.newPresentation.event_roomsID = this.workingEvent.rooms[0].event_roomsID;
				}
			}
			this.newPresentation.event_roomsID = scheduleRow.event_roomsID;
			this.newPresentation.presentation_typesID = scheduleRow.presentation_typesID;
		},
		updateSchedule() {
			if(this.newPresentation.ID && this.newPresentation.ID != '' && this.newPresentation.ID != undefined){
				this.axios.patch('/PresentationSchedule', this.newPresentation).then((response) => {
					if (response.status == 200) {
						for(let i=0; i<this.currentPresentationSchedule.length; i++){
							if(this.currentPresentationSchedule[i].presentation_scheduleID == this.newPresentation.ID){
								this.currentPresentationSchedule[i] = response.data.record;
							}
						}
						this.clearNewPresentationForm();
					} else {
						alert("Could not update the presentation on the server\n" + response.message);
					}
				});
			}
		},
		setRoomValue(room){
			console.log(room);
			this.newPresentation.event_roomsID = room.event_roomsID;
			console.log(this.newPresentation);
		},
    download(row){
      console.log(row);
      let downloadIDs = [row.presentationFile.presentation_filesID];
      window.open(`https://sync.meetingfiles.com/api/public/download/?${downloadIDs.map((n, index) => `scheduleIDs[${index}]=${n}`).join('&')}&room=` + encodeURI(row.room.room_name) + `&date=` + encodeURI(row.presentation_date));
    },
	},
	computed: {
		minDate() {
			return this.workingEvent ? this.workingEvent.event_start_date : null;
		},
		maxDate() {
			return this.workingEvent ? this.workingEvent.event_end_date : null;
		},
		roomValue(){
			return this.newPresentation.event_roomsID ? this.newPresentation.event_roomsID : '';
		}
	},
  watch: {
    '$route.params.ID': function(newID) {
      this.presentersID = newID;
      this.setPresenter(); // Reload presenter data based on the new ID
    }
  },
	mounted() {
		this.presentersID = this.$route.params.ID;
		this.setPresenter();
	}
}
</script>

<style scoped>

</style>
