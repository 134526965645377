<template>
  <div id="eventsView">
    <div class="row mb-5">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div v-if="myCurrentEventsArray">
              <table class="table">
                <thead>
                <tr>
                  <th>Event Name</th>
                  <th>Event Dates</th>
                  <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="event in myCurrentEventsArray" :key="event.eventsID">
                  <td>{{ event.event_title }}</td>
                  <td>{{ scheduleDate(event.event_start_date) }} - {{ scheduleDate(event.event_end_date) }}</td>
                  <td class="">
                    <router-link :to="{ name: 'EventDetailView', params: {ID: event.eventsID}}"
                                 class="btn btn-secondary me-3"><i class="bi bi-pencil-square"></i> view/edit
                    </router-link>
                    <router-link :to="{ name: 'ScheduleUploadView', params: {ID: event.eventsID}}"
                                 class="btn btn-secondary me-3"><i class="bi bi-upload"></i> schedule upload
                    </router-link>
                    <a :href="`https://sync.meetingfiles.com/api/public/plinks/?eid=${ event.eventsID }`"
                       class="btn btn-secondary"><i class="bi bi-share-fill"></i> Presenter Links</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <p class="alert alert-warning">
                No event data to show.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4>Add a New Event</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="createNewEvent">
              <div class="mb-3">
                <label class="form-label">Company / Organization <em>(type to search database)</em></label>
                <input type="text" class="form-control" @keyup="searchOrg" v-model="searchName"/>
                <input type="hidden" class="form-control" @keyup="searchOrg" v-model="newEventArray.organizationsID"/>
                <div v-if="orgSearchArray">
                  <div class="mt-2 list-group">
                    <a href="#" class="list-group-item list-group-item-action" v-for="org in orgSearchArray"
                       :key="org.organizationsID" @click.stop.prevent="selectOrg(org)">({{ org.organization_acronym }})
                      {{ org.organization_name }}</a>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">Event Title</label>
                <input type="text" class="form-control" v-model="newEventArray.event_title"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Event Start Date</label>
                <input type="date" class="form-control" v-model="newEventArray.event_start_date"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Event End Date</label>
                <input type="date" class="form-control" v-model="newEventArray.event_end_date"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Event Location (i.e. Hotel or Venue)</label>
                <input type="text" class="form-control" v-model="newEventArray.event_location"/>
              </div>

              <!-- Numbering -->
              <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" v-model="newEventArray.show_number_label"/>
                <label class="form-check-label">Show Number Label?</label>
              </div>
              <div class="mb-3" v-if="newEventArray.show_number_label">
                <label class="form-label">Enter Number Label (<span class="small">eg: Poster #:</span>)</label>
                <input type="text" maxlength="50" class="form-control" v-model="newEventArray.number_label_text"
                       placeholder="Poster #:"/>
              </div>

              <!-- Disclosure -->
              <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" v-model="newEventArray.show_disclosure"/>
                <label class="form-check-label">Show Disclosure Requirement Text?</label>
              </div>
              <div class="mb-3" v-if="newEventArray.show_disclosure">
                <label class="form-label">Enter Disclosure Requirement Text</label>
                <textarea class="form-control" v-model="newEventArray.event_disclosure_text"></textarea>
              </div>

              <!-- Record Authorization -->
              <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" v-model="newEventArray.show_record_auth"/>
                <label class="form-check-label">Show Record Authorization Check</label>
              </div>
              <div class="mb-3" v-if="newEventArray.show_record_auth ">
                <label class="form-label">Record Authorization Text?</label>
                <textarea class="form-control" v-model="newEventArray.record_auth_text"></textarea>
              </div>

              <!-- Consent -->
              <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" v-model="newEventArray.show_consent_check"/>
                <label class="form-check-label">Show Consent Check</label>
              </div>
              <div class="mb-3" v-if="newEventArray.show_consent_check ">
                <label class="form-label">Consent Text?</label>
                <textarea class="form-control" v-model="newEventArray.consent_text"></textarea>
              </div>
              <hr>

              <!-- Rooms -->
              <div class="">
                <h5>Rooms</h5>
                <p>One Room is Required. Add as many additional rooms as you like.</p>
              </div>
              <div class="mb-3">
                <label class="form-label">Main Room Name</label>
                <input type="text" v-model="mainRoom" class="form-control"/>
              </div>
              <button type="button" @click.prevent.stop="addRoom" class="mb-2 btn btn-secondary">+ room</button>
              <div v-if="additionalRooms">
                <div v-for="(room, index) in additionalRooms" :key="index" class="input-group mb-2">
                  <input type="text" class="form-control" v-model="room.roomName" placeholder="Room Name"
                         aria-label="Room Name" aria-describedby="Room Name">
                  <span class="input-group-text" id="Room Name" @click="removeRoom(index)"><i
                      class="bi bi-x-circle-fill"></i></span>
                </div>
              </div>
              <hr>

              <div class="mb-3">
                <input type="submit" class="btn btn-success" value="submit"/>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventsView",
  title: 'SYNC Events',
  data() {
    return {
      newEventArray: {
        groupsID: '',
        organizationsID: '',
        event_title: '',
        event_start_date: '',
        event_end_date: '',
        event_location: '',
        show_disclosure: 0,
        event_disclosure_text: '',
        show_record_auth: 0,
        record_auth_text: '',
        show_consent_check: 0,
        consent_text: '',
        show_number_label: 0,
        number_label_text: ''
      },
      orgSearchArray: null,
      searchName: '',
      mainRoom: '',
      additionalRooms: []
    }
  },
  methods: {
    searchOrg() {
      let parameters = {searchClass: 'Organization', searchMethod: 'searchByNames', term: this.searchName}
      if (this.searchName.length >= 3) {
        this.axios.get("/public/search/", {params: parameters}).then(response => (this.orgSearchArray = response.data.record));
      }
    },
    createNewEvent() {
      if (this.validateEventForm()) {
        this.newEventArray.groupsID = this.$store.getters['user/getMyGroupID'];
        this.axios.post('/Event', this.newEventArray).then((response) => {
          if (response.data.status == 200) {
            let eventID = response.data.record.eventsID;
            this.$store.dispatch('events/addEventToArray', response.data.record);
            this.processRooms(eventID);
          }
        });
      }
    },
    selectOrg(org) {
      this.newEventArray.organizationsID = org.organizationsID;
      this.searchName = org.organization_name;
      this.orgSearchArray = null;
    },
    validateEventForm() {
      if (this.mainRoom !== '') {
        return true;
      } else {
        alert("At least one room is required. Please indicate the main room.");
        return false;
      }
    },
    addRoom() {
      this.additionalRooms.push({roomName: ''});
    },
    removeRoom(index) {
      this.additionalRooms.splice(index, 1);
    },
    processRooms(eventID) {
      for (let i in this.additionalRooms) {
        let data = {eventsID: eventID, room_name: this.additionalRooms[i].roomName};
        this.axios.post('/EventRoom', data);
      }
    },
    scheduleDate(date) {
      let d = this.dayjs(date, "YYYY-MM-DD");
      return d.format("MMMM D, YYYY");
    }
  },
  computed: {
    myCurrentEventsArray() {
      return this.$store.getters['events/currentEventsArray'];
    },
  },
  mounted() {
    this.$store.dispatch('events/setEventsData');
  }
}
</script>

<style scoped>

</style>
