import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from "axios";
import dayjs from "dayjs";

/**CSS**/

//import "bootstrap/dist/css/bootstrap.min.css";
import '@/assets/css/custom.scss';
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";

/** Axios **/
const instance = axios.create({
    baseURL: "https://sync.meetingfiles.com/api",
	withCredentials: true,
	headers: {
		'Content-Type': 'multipart/form-data'
	}
});

/** Mixin **/
import titleMixin from './mixins/titleMixin';

let customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat)

const syncApp = createApp(App);
syncApp.use(router);
syncApp.use(store);
syncApp.mixin(titleMixin);

syncApp.config.globalProperties.axios = instance;
syncApp.config.globalProperties.dayjs = dayjs;

syncApp.mount('#app');
