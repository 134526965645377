<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div v-if="workingEvent">
                    <div class="card">
                        <div class="card-header">
                            <h4>Upload a Schedule for Event: {{ workingEvent.event_title }}</h4>
                        </div>
                        <div class="card-body">
                            <form id="scheduleUploadForm" @submit.prevent.stop="processSchedule">
                                <div class="mb-3">
                                    <label class="form-label">Select Your CSV File</label>
                                    <input type="file" name="csv-schedule" class="form-control" @change="fileChange"/>
                                </div>
                                <div>
                                    <input type="submit" name="submit" value="submit" class="btn btn-success"/>
                                </div>
                            </form>
                            <div class="mt-3">

                                <div v-if="errorMessage" class="">
                                    <p class="alert alert-danger">{{ errorMessage }}</p>
                                </div>
                                <div v-if="showErrorArray" class="mt-2">
                                    <p v-for="(error, index) in errorArray" :key="index" class="alert alert-danger">{{ error }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EventDetailView",
		title: 'SYNC Event Detail',
        data() {
            return {
                scheduleFile: null,
                eventsID: null,
                showErrorMessage: false,
                showErrorArray: true,
                errorMessage: null,
                errorArray: null
            }
        },
        methods: {
            fileChange(event){
                let file = event.target.files[0];
                this.scheduleFile = file;
            },
            processSchedule(){
                let formData = new FormData();
                formData.append("altMethod", "PUT");
                formData.append("upload-file", this.scheduleFile);
                formData.append("eventsID", this.eventsID);
                this.axios.post('/UploadSchedule', formData).then((response) => {
                    if(response.data.status == 200){
                      console.log('success');
                    }
                    else{
                      console.log(response);
                      if(response.data.record){
                        if(response.data.record.message){
                          if(response.data.record.message){
                            this.errorMessage = response.data.record.message;
                            this.showErrorMessage = true;
                          }
                          if(response.data.record.errors){
                            this.errorArray = response.data.record.errors;
                            this.showErrorArray = true;
                          }
                        }
                        else{
                          this.errorMessage = ["There was n error but no message from the server"];
                          this.showErrorMessage = true;
                        }
                      }
                      else{
                        this.errorMessage = "There was n error but no message from the server";
                        this.showErrorMessage = true;
                      }
                    }
                });
            }
        },
        computed: {
            workingEvent(){
                return this.$store.getters['events/workingEvent'];
            },
        },
        mounted(){
            this.eventsID = this.$route.params.ID;
            this.$store.dispatch('events/setWorkingEvent', this.$route.params.ID);
            for(let i in this.workingEvent.rooms){
                this.workingEvent.rooms[i].isEditing = false;
            }
        }

    }
</script>

<style scoped>

</style>
