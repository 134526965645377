import groupService from "@/service/GroupService";

export const namespaced = true;

export const state = {
    groupsArray: null
};

export const getters = {
    getGroupsArray: state=>{
        return state.groupsArray;
    }
};

export const mutations = {
    setGroupArray(state, groupsData){
        state.groupsArray = groupsData
    },
    addGroupToArray(state, group){
        state.groupsArray.push(group);
    },
    addUserToGroup(state, user){
        if(Array.isArray(state.groupsArray)){
            for(let i in state.groupsArray){
                if(state.groupsArray[i].groupsID == user.groupsID){
                    if(Array.isArray(state.groupsArray[i].users)){
                        console.log("is array");
                        state.groupsArray[i].users.push(user);
                    }
                    else{
                        console.log("not array make");
                        state.groupsArray[i].users = [user];
                    }
                    return
                }
                else{
                    console.log("not group: "+state.groupsArray[i].group_name);
                }
            }
        }
        else{
            console.log('groupsarray not array');
        }
    }
};

export const actions = {
    setGroupsData({commit}){
        return new Promise((resolve, reject) => {
            groupService.getAll().then((response) => {
                if (response.data.status === 200){
                    commit('setGroupArray', response.data.record);
                }
                else{
                    reject();
                }
            }, (error) => {
                console.log(error);
                reject();
            });
        });
    },
    addGroupToArray({commit}, payload){
        commit('addGroupToArray', payload);
    },
    addUserToGroup({commit}, payload){
        commit('addUserToGroup', payload);
    }
};
