<template>
    <div>
        <Header/>
        <div class="container-fluid">
            <div class="row">
                <Sidebar/>
                <main class="col-md-10 ms-sm-auto col-lg-11 px-md-4 mt-3">
                    <router-view/>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'

    import Sidebar from './Sidebar'
    import Header from './Header'
    export default {
        name: 'AppFrame',
        computed: {
            ...mapState(['sideBarOpen'])
        },
        components: {
            Sidebar,
            Header
        },
        mounted(){
            this.$store.dispatch('user/isAuthenticated');

        }
    }

</script>
<style scoped lang="scss">
    .side-bar-container {
        background-color: #c0c0c0;
    }
</style>
