import userService from '../../service/UserService';

export const namespaced = true;

export const state = {
	userData: {},
	authenticated: false,
	authenticationErrorMessage: '',
};

export const getters = {
	authenticated: state => {
		return state.authenticated
	},
	userData: state => {
		return state.userData
	},
	authenticationErrorMessage: state => {
		return state.authenticationErrorMessage;
	},
	userLevel: state => {
		if (state.userData) {
			return state.userData.user_access_level;
		}
		else {
			return null;
		}
	},
	getMyGroupID: state => {
		if(state.userData){
			return state.userData.groupsID;
		}
		else{
			return false;
		}
	},
};

export const mutations = {
	updateUserData(state, userData) {
		state.userData = userData
	},
	setAuthentication(state, authenticated) {
		state.authenticated = authenticated;
		localStorage.setItem('authenticated', authenticated);
	},
	setAuthenticationErrorMessage(state, msg) {
		state.authenticationErrorMessage = msg;
	},
};

export const actions = {
	isAuthenticated({commit}) {
		return new Promise((resolve, reject) => {
			userService.checkAuthentication().then((response) => {
				if (response.data.status === 200) {
					commit('updateUserData', response.data.record);
					commit('setAuthentication', true);
					resolve(true);  // Let the calling function know that http is done. You may send some data back
				} else {
					commit('setAuthentication', false);
					reject(false);
				}
			}, (error) => {
				console.log(error);
				commit('setAuthentication', false);
				reject();
			});
		});
	},
	authenticateUser({commit}, payload) {
		return new Promise((resolve, reject) => {
			userService.authenticate(payload.username, payload.password).then((response) => {
				if (response.data.status === 200) {
					commit('setAuthentication', true);
					commit('updateUserData', response.data.userData);
					resolve();
				} else {
					commit('setAuthenticationErrorMessage', 'User Credentials Not Accepted...');
					reject();
				}
			}, (error) => {
				commit('setAuthenticationErrorMessage', 'There was a server error. Please try again or contact the Simple To Manage Team.' + error);
				reject();
			});
		});
	},
	updateUserData({commit, state}) {
		return new Promise((resolve, reject) => {
			userService.update(state.userData.userID, state.userData).then((response) => {
				if (response.data.status === 200) {
					console.log(response.data.record);
					commit('updateUserData', response.data.record);
					resolve(response);
				} else {
					reject(response.data.message);
				}
			}, (error) => {
				reject(error);
			});
		});
	},
	logOut({commit}) {
		return new Promise((resolve, reject) => {
			userService.logOut().then((response) => {
				if (response.data.status === 200) {
					commit('setAuthentication', false);
					commit('updateUserData', null);
					resolve();
				} else {
					reject(response.data.message);
				}
			}, (error) => {
				reject(error);
			});
		});
	}
};
