<template>
  <div>
    <div class="">
      <input type="text" class="form-control" placeholder="Search Presenters" v-model="searchName" @keyup="searchPresenters"/>
    </div>
    <div v-if="showSearchResults" id="presenterSearchResults">
      <table class="table">
        <tbody>
        <tr v-for="searchResult in searchResults" :key="searchResult.presentersID">
          <td>{{ searchResult.presenters_fname }}</td>
          <td>{{ searchResult.presenters_lname }}</td>
          <td><a :href="'mailto:' + searchResult.presenters_email">{{ searchResult.presenters_email }}</a></td>
          <td class="btn-group">
            <button class="btn btn-sm btn-secondary" @click="editPresenter(searchResult)">
              <i class="bi bi-pencil-square"></i> edit presenter
            </button>
            <button class="btn btn-sm btn-secondary" @click="goToSchedule(searchResult)">
              <i class="bi bi-file-earmark-plus"></i> presenter schedule
            </button>
            <button class="btn btn-sm btn-secondary" @click="goToSync(searchResult)">
              <i class="bi bi-link-45deg"></i> goto sync
            </button>
            <a
                href="javascript:void(0)"
                class="btn btn-sm btn-secondary"
                @click="copyLink(searchResult)"
            >Copy Link</a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <p v-if="showNoSearchResultsAlert" class="alert alert-danger">
      <i class="bi bi-exclamation-octagon-fill"></i> No Results for this Name</p>
  </div>
</template>

<script>
export default {
  name: "PresenterSearchForm",
  data(){
    return {
      searchName: '',
      searchResults: [],
      showSearchResults: false,
      showNoSearchResultsAlert: false
    }
  },
  methods:{
    searchPresenters: function () {
      let parameters = {searchClass: 'Presenter', searchMethod: 'searchByNames', term: this.searchName}

      if (this.searchName.length >= 3) {
        this.axios.get("/public/search/", {params: parameters}).then(response => (this.processSearchResults(response.data)));
      } else {
        this.showSearchResults = false;
        this.searchResults = [];
      }
    },
    processSearchResults: function (data) {
      if (data.record.length > 0) {
        this.showNoSearchResultsAlert = false;
        this.showSearchResults = true;
        this.searchResults = data.record;
      } else {
        this.showNoSearchResultsAlert = true;
      }
    },
    editPresenter(selectedPresenter){
      let params = {ID: selectedPresenter.presentersID}
      this.$router.push({name: 'PresenterView', params});
      this.showSearchResults = false;
    },
    goToSchedule(selectedPresenter) {
      let params = {ID: selectedPresenter.presentersID}
      this.$router.push({name: 'PresenterScheduleView', params});
      this.showSearchResults = false;
    },
    goToSync(presenter) {
      let url = encodeURI('https://sync.meetingfiles.com/my-sync/auth.php?anyscreen=true&uuid=' + presenter.uuid);
      window.open(url, "_blank");
      this.showSearchResults = false;
    },
    copyLink(searchResult) {
      const link = `https://sync.meetingfiles.com/my-sync/?uuid=${searchResult.uuid}&pin=${searchResult.presenters_pin}`;

      // Copy the link to the clipboard
      navigator.clipboard.writeText(link)
          .then(() => {
            // Optional: Show a success message
          })
          .catch(err => {
            console.error('Failed to copy link:', err);
          });
    }
  }
}
</script>

<style scoped>
#presenterSearchResults{
  position: absolute;
  z-index: 5000;
  background-color: #262A32;
  padding: 8px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 3px 3px 12px #c0c0c0;
  min-width: 940px;
}
.table td {
  border-bottom: 1px solid #fce587;
}
</style>
