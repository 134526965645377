//import groupService from "@/service/GroupService";

export const namespaced = true;

export const state = {
	syncMachine: {}
};

export const getters = {
	getSyncMachine: state=>{
		return state.syncMachine;
	}
};

export const mutations = {
	setSyncMachine(state, syncMachineData){
		state.syncMachine = syncMachineData
	},
};

export const actions = {
	setSyncMachine({commit}, payload){
		commit('setSyncMachine', payload);
	}
};
