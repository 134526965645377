
export const namespaced = true;

export const state = {
    workingPresenterData: {}
};

export const getters = {
    workingPresenterData: state => {
        return state.workingPresenterData;
    }
};

export const mutations = {
    updateWorkingPresenterData(state, presenterData){
        state.workingPresenterData = presenterData;
        console.log(state.workingPresenterData);
    },
};

export const actions = {
    setWorkingPresenterData({commit}, payload){
        commit('updateWorkingPresenterData', payload);
    },
};
