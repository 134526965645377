<template>
	<header class="navbar navbar-expand-md navbar-dark sticky-top bg-dark p-0 shadow">
		<div class="container-fluid">
			<a class="navbar-brand d-flex text-center" href="#">
				<img src="https://meetingfiles.com/assets/images/sync-logo-white.png" class="" width="100"/>
			</a>
			<button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
      <div class="w-50 ms-auto">
        <PresenterSearchForm />
      </div>

			<ul class="nav navbar-nav ms-auto justify-content-end">
        <li class="nav-item">

        </li>
				<li class="nav-item" v-if="syncStation">
					<router-link :to="{ name: 'EventRoomSchedule', params: {ID: syncStation.event_roomsID}}" class="nav-link px-3 text-primary">{{ syncStation.station_name }} - {{ syncStation.room_name }}</router-link>
				</li>
				<li class="nav-item">
					<a class="nav-link px-3" href="#" @click.prevent.stop="exitApp()">Sign out</a>
				</li>
			</ul>
		</div>
	</header>
</template>

<script>
import PresenterSearchForm from '@/components/Forms/PresenterSearchForm';

export default {
	name: "Header",
  components:{
    PresenterSearchForm
  },
	computed: {
		syncStation(){
			return this.$store.getters['syncMachine/getSyncMachine'];
		}
	},
	methods: {
		exitApp() {
			this.$store.dispatch('user/logOut').then(() => {
				this.$router.push("/");
			}, (reject) => {
				console.log(reject);
			});
		},
		goToRoom(){
			if(this.syncStation){
				this.$router.push("/app/events/schedule/view/"+this.syncStation.event_roomsID);
			}
		}
	}
}
</script>

<style scoped>

</style>
