import { createRouter, createWebHashHistory } from 'vue-router';
import store from '../store';

import Home from '../components/Home';
import Dashboard from '../components/AppFrame';
import DashboardHome from '../views/Dashboard/Dashboard';
import PresenterView from "@/views/Presenter/PresenterView";
import PresenterScheduleView from "@/views/Presenter/PresenterScheduleView";
import EventsView from "@/views/Events/EventsView";
import OrganizationsView from "@/views/Organizations/OrganizationsView";
import UsersAdminView from "@/views/Admin/UsersAdminView";
import GroupsAdminView from "@/views/Admin/GroupsAdminView";
import EventDetailView from "@/views/Events/EventDetailView";
import ScheduleUploadView from "@/views/Events/ScheduleUploadView";
import EventRoomSchedule from "@/views/Events/EventRoomSchedule";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
		meta: {
			title: 'Meeting Files SYNC!',
		}
    },
    {
        path: '/app',
        redirect: { name: 'DashboardHome' },
        meta: {requiresAuth: true, title: 'SYNC!' }
    },
    {
        path: '/app',
        component: Dashboard,
        children: [
            { path: 'dashboard', name: 'DashboardHome', component: DashboardHome, meta: {requiresAuth: true, title: 'SYNC!' }},
            { path: 'presenters', name: 'PresenterView', component: PresenterView, meta: {requiresAuth: true, title: 'SYNC!' }},
            {
                path: 'presenters/schedule/:ID',
                name: 'PresenterScheduleView',
                component: PresenterScheduleView,
                meta: {requiresAuth: true, title: 'SYNC!' },
                children: []
            },
            {
                path: 'organizations',
                name: 'OrganizationsView',
                component: OrganizationsView,
                meta: {requiresAuth: true, title: 'SYNC!' },
                children: []
            },
            {
                path: 'events',
                name: 'EventsView',
                component: EventsView,
                meta: {requiresAuth: true, title: 'SYNC!' },
                children: []
            },
                {
                    path: 'events/view/:ID',
                    name: 'EventDetailView',
                    component: EventDetailView,
                    meta: {requiresAuth: true, title: 'SYNC!' },
                    children: []
                },
                {
                    path: 'events/schedule/view/:ID',
                    name: 'EventRoomSchedule',
                    component: EventRoomSchedule,
                    meta: {requiresAuth: true, title: 'SYNC!' },
                    children: []
                },
                {
                    path: 'events/schedule/upload/:ID',
                    name: 'ScheduleUploadView',
                    component: ScheduleUploadView,
                    meta: {requiresAuth: true, title: 'SYNC!' },
                    children: []
                },
            {
                path: 'admin/users',
                name: 'UsersAdminView',
                component: UsersAdminView,
                meta: {requiresAuth: true, adminOnly: true, title: 'SYNC!' },
                children: []
            },
            {
                path: 'admin/groups',
                name: 'GroupsAdminView',
                component: GroupsAdminView,
                meta: {requiresAuth: true, adminOnly: true, title: 'SYNC!' },
                children: []
            }
        ]
    },


];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.getters['user/authenticated']) {
            next({ name: 'Home' })
        } else {
            next() // go to wherever I'm going
        }
    }
    else if(to.matched.some(record => record.meta.adminOnly)){
        if(this.$store.getters['user/userLevel'] !== 1 ){
            console.log('You do not have permission to access this area.');
        }
        else{
            next();
        }
    }
    else {
        next() // does not require auth, make sure to always call next()!
    }

});



export default router
