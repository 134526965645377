import organizationService from "@/service/OrganizationService";

export const namespaced = true;

export const state = {
    organizationsArray: null
};

export const getters = {
    organizationsArray: state=>{
        return state.organizationsArray;
    }
};

export const mutations = {
    setOrganizationsArray(state, organizationData){
        state.organizationsArray = organizationData
    },
    addOrganizationToArray(state, organization){
        state.organizationsArray.push(organization);
    },

};

export const actions = {
    setOrganizationsData({commit}){
        return new Promise((resolve, reject) => {
            organizationService.getAll().then((response) => {
                if (response.data.status === 200){
                    commit('setOrganizationsArray', response.data.record);
                }
                else{
                    reject();
                }
            }, (error) => {
                console.log(error);
                reject();
            });
        });
    },
    addOrganizationToArray({commit}, payload){
        commit('addOrganizationToArray', payload);
    }
};
