<template>
	<div>
		<div v-if="error">
			<p class="alert alert-danger">{{ errorMessage }}</p>
		</div>
		<div v-else>
			<div class="row">
				<div class="col-md-6">
					<div v-if="workingEvent">
						<div class="card">
							<div class="card-header">
								<h4>Edit Event: {{ workingEvent.event_title }}</h4>
							</div>
							<div class="card-body">
								<form @submit.prevent="updateEvent">
									<div class="mb-3">
										<label class="form-label">Company / Organization <em>(type to search database)</em></label>
										<input v-if="workingEvent.organization" type="text" class="form-control" v-model="workingEvent.organization.organization_name"/>
										<input type="hidden" class="form-control" v-model="workingEvent.organizationsID"/>
										<div v-if="orgSearchArray">
											<div class="mt-2 list-group">
												<a href="#" class="list-group-item list-group-item-action" v-for="org in orgSearchArray" :key="org.organizationsID" @click.stop.prevent="selectOrg(org)">({{ org.organization_acronym }}) {{ org.organization_name }}</a>
											</div>
										</div>
									</div>
									<div class="mb-3">
										<label class="form-label">Event Title</label>
										<input type="text" class="form-control" v-model="workingEvent.event_title"/>
									</div>
									<div class="mb-3">
										<label class="form-label">Event Start Date</label>
										<input type="date" class="form-control" v-model="workingEvent.event_start_date"/>
									</div>
									<div class="mb-3">
										<label class="form-label">Event End Date</label>
										<input type="date" class="form-control" v-model="workingEvent.event_end_date"/>
									</div>
									<div class="mb-3">
										<label class="form-label">Event Location (i.e. Hotel or Venue)</label>
										<input type="text" class="form-control" v-model="workingEvent.event_location"/>
									</div>

									<!-- Numbering -->
									<div class="mb-3 form-check">
										<input type="checkbox" class="form-check-input" v-model="workingEvent.show_number_label" true-value="1" false-value="0"/>
										<label class="form-check-label">Show Number Label?</label>
									</div>
									<div class="mb-3" v-if="workingEvent.show_number_label">
										<label class="form-label">Enter Number Label (<span class="small">eg: Poster #:</span>)</label>
										<input type="text" maxlength="50" class="form-control" v-model="workingEvent.number_label_text" placeholder="Poster #:"/>
									</div>

									<!-- Disclosure -->
									<div class="mb-3 form-check">
										<input type="checkbox" class="form-check-input" v-model="workingEvent.show_disclosure" true-value="1" false-value="0"/>
										<label class="form-check-label">Show Disclosure Requirement Text?</label>
									</div>
									<div class="mb-3" v-if="workingEvent.show_disclosure">
										<label class="form-label">Enter Disclosure Requirement Text</label>
										<textarea class="form-control" v-model="workingEvent.event_disclosure_text"></textarea>
									</div>

									<!-- Record Auth -->
									<div class="mb-3 form-check">
										<input type="checkbox" class="form-check-input" v-model="workingEvent.show_record_auth" true-value="1" false-value="0"/>
										<label class="form-check-label">Show Record Authorization Check</label>
									</div>
									<div class="mb-3" v-if="workingEvent.show_record_auth ">
										<label class="form-label">Record Authorization Text?</label>
										<textarea class="form-control" v-model="workingEvent.record_auth_text"></textarea>
									</div>

									<!-- Consent -->
									<div class="mb-3 form-check">
										<input type="checkbox" class="form-check-input" v-model="workingEvent.show_consent_check" true-value="1" false-value="0"/>
										<label class="form-check-label">Show Consent Check</label>
									</div>
									<div class="mb-3" v-if="workingEvent.show_consent_check ">
										<label class="form-label">Consent Text?</label>
										<textarea class="form-control" v-model="workingEvent.consent_text"></textarea>
									</div>

									<div class="mb-3">
										<input type="submit" class="btn btn-success" value="submit"/>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6">

					<!-- Rooms -->
					<div class="card mb-3">
						<div class="card-header">
							<h4>Rooms</h4>
						</div>
						<div class="card-body">
							<div>
								<div class="mb-2">
									<label class="form-label">New Room Name</label>
									<input type="text" class="form-control" v-model="newRoom" placeholder="Room Name" aria-describedby="Room Name">
								</div>
								<button type="button" @click.prevent.stop="addRoom" class="mb-2 btn btn-secondary">+ add this room</button>
							</div>
							<hr>
							<div v-if="workingEvent.rooms">
								<table class="table">
									<tbody>
									<tr v-for="(room, index) in workingEvent.rooms" :key="room.event_roomsID">
										<td>
											<input type="text" class="form-control" v-model="room.room_name" :disabled="!room.isEditing" placeholder="Room Name" aria-describedby="Room Name">
										</td>
										<td>
											<router-link :to="{ name: 'EventRoomSchedule', params: {ID: room.event_roomsID}}" class="btn btn-secondary me-3">
												<i class="bi bi-calendar-date"></i></router-link>
											<button type="button" v-if="!room.isEditing" class="btn btn-secondary me-3" @click="editRoom(index)">
												<i class="bi bi-pencil-square"></i></button>
											<button type="button" v-if="room.isEditing" class="btn btn-success me-3" @click="saveRoom(index)">
												<i class="bi bi-check-circle"></i></button>
											<button type="button" class="btn btn-secondary me-2" @click="removeRoom(index)">
												<i class="bi bi-x-circle-fill"></i></button>
										</td>
									</tr>
									</tbody>
								</table>
							</div>
							<div v-else>
								<p class="alert alert-danger">There are no rooms for this event</p>
							</div>
						</div>
					</div>

					<!-- Sync Stations -->
					<div class="card">
						<div class="card-header">
							<h4>Sync Stations</h4>
							<p><em>In order to synchronize the server to your workstations you need to create each one here.</em></p>
						</div>
						<div class="card-body">
							<div>
								<div class="mb-2">
									<label class="form-label">New Sync Station Name</label>
									<input type="text" class="form-control" v-model="newSyncStationName" placeholder="Sync Station Name" aria-describedby="Sync Station Name">
								</div>
								<button type="button" @click.prevent.stop="addStation" class="mb-2 btn btn-secondary">+ add this sync station</button>
							</div>
							<hr>
							<div v-if="workingEvent.syncStations">
								<table class="table">
									<tbody>
									<tr v-for="(station, index) in workingEvent.syncStations" :key="station.sync_stationsID">
										<td>
											<input type="text" class="form-control" v-model="station.station_name" :disabled="!station.isEditing" placeholder="Room Name" aria-describedby="Room Name">
										</td>
										<td>
											<button type="button" v-if="!station.isEditing" class="btn btn-secondary me-3" @click="editStation(index)">
												<i class="bi bi-pencil-square"></i></button>
											<button type="button" v-if="station.isEditing" class="btn btn-success me-3" @click="saveStation(index)">
												<i class="bi bi-check-circle"></i></button>
											<button type="button" class="btn btn-secondary me-2" @click="removeStation(index)">
												<i class="bi bi-x-circle-fill"></i></button>
										</td>
									</tr>
									</tbody>
								</table>
							</div>
							<div v-else>
								<p class="alert alert-danger">There are no sync stations for this event</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EventDetailView",
	title: 'SYNC Event Detail',
	data() {
		return {
			orgSearchArray: null,
			newRoom: '',
			newSyncStationName: '',
			error: false,
			errorMessage: ''
		}
	},
	methods: {
		updateEvent() {
			this.workingEvent.ID = this.workingEvent.eventsID;
			this.axios.patch('/Event', JSON.stringify(this.workingEvent)).then((response) => {
				if (response.data.status == 200) {
					this.$store.dispatch('events/updateEventArray', response.data.record);
				}
			});
		},
		removeRoom(index) {
			/**ToDo
                1. check for schedule
                 2. confirmation`
			 **/
			let event_roomsID = this.workingEvent.rooms[index].event_roomsID;
			this.axios.delete('/EventRoom/', {params: {event_roomsID: event_roomsID}}).then((response) => {
				if (response.status == 200) {
					this.workingEvent.rooms.splice(index, 1);
					let payload = {eventsID: this.workingEvent.eventsID, event_roomsID: event_roomsID};
					this.$store.dispatch('events/removeRoom', payload);
				}
			});
		},
		addRoom() {
			if (this.newRoom != '') {
				let data = {eventsID: this.workingEvent.eventsID, room_name: this.newRoom};
				this.axios.post('/EventRoom', data).then((response) => {
					if (response.data.status == 200) {
						if (Array.isArray(this.workingEvent.rooms)) {
							this.workingEvent.rooms.push(response.data.record);
						} else {
							this.workingEvent.rooms = [response.data.record];
						}
						let payload = {eventsID: this.workingEvent.eventsID, newRoom: response.data.record};
						this.$store.dispatch('events/addRoomToEvent', payload);
						this.newRoom = null;
					} else {
						alert('There was an error adding the new room');
					}

				});
			} else {
				alert("Room name can not be blank");
			}
		},
		editRoom(index) {
			this.workingEvent.rooms[index].isEditing = true;
		},
		saveRoom(index) {
			let data = {};
			data.ID = this.workingEvent.rooms[index].event_roomsID;
			data.room_name = this.workingEvent.rooms[index].room_name;
			this.axios.patch('/EventRoom', JSON.stringify(data)).then((response) => {
				this.$store.dispatch('events/updateEventRoom', response.data.record);
				this.workingEvent.rooms[index] = response.data.record;
				alert('Room was updated in the API');
			});
		},

		addStation() {
			if (this.newSyncStationName != '') {
				let data = {eventsID: this.workingEvent.eventsID, station_name: this.newSyncStationName};
				this.axios.post('/SyncStation', data).then((response) => {
					if (response.data.status == 200) {
						if (Array.isArray(this.workingEvent.syncStations)) {
							this.workingEvent.syncStations.push(response.data.record);
						} else {
							this.workingEvent.syncStations = [response.data.record];
						}
						let payload = {eventsID: this.workingEvent.eventsID, newStation: response.data.record};
						this.$store.dispatch('events/addStationToEvent', payload);
						this.newSyncStationName = null;
					} else {
						alert('There was an error adding the new station.');
					}

				});
			} else {
				alert("Station name can not be blank");
			}
		},
		editStation(index) {
			this.workingEvent.syncStations[index].isEditing = true;
		},
		saveStation(index) {
			let data = {};
			data.ID = this.workingEvent.syncStations[index].sync_stationsID;
			data.station_name = this.workingEvent.syncStations[index].station_name;
			this.axios.patch('/SyncStation', JSON.stringify(data)).then((response) => {
				this.$store.dispatch('events/updateEventStation', response.data.record);
				this.workingEvent.syncStations[index] = response.data.record;
				alert('Station was updated in the API');
			});
		},
		removeStation(index) {
			/**ToDo
                1. logged in
                 2. confirmation`
			 **/
			let sync_stationsID = this.workingEvent.syncStations[index].sync_stationsID;
			this.axios.delete('/SyncStation/', {params: {sync_stationsID: sync_stationsID}}).then((response) => {
				if (response.status == 200) {
					this.workingEvent.syncStations.splice(index, 1);
					let payload = {eventsID: this.workingEvent.eventsID, sync_stationsID: sync_stationsID};
					this.$store.dispatch('events/removeStation', payload);
				}
			});
		},
	},
	computed: {
		workingEvent() {
			return this.$store.getters['events/workingEvent'];
		}
	},
	mounted() {
		this.axios.get('/Event/' + this.$route.params.ID).then((response) => {
			if (response.data.status == 200) {
				this.$store.dispatch('events/setWorkingEvent', response.data.record);
				for (let i in this.workingEvent.rooms) {
					this.workingEvent.rooms[i].isEditing = false;
				}
			}
			else{
				this.error = true;
				this.errorMessage = 'Could not get record. You either do not have access to this record or id does not exist.';
			}
		}, (error) => {
			console.log(error);
		});
	}

}
</script>

<style scoped>

</style>
