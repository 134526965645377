<template>
	<div id="dashboard">

		<!-- Events -->
		<div v-if="myGroupsEvents">
			<div v-for="event in myGroupsEvents" :key="event.eventsID">
				<div class="card mb-3 shadow-sm">

					<div class="card-body">
						<div class="">
							<h4>{{ event.event_title }} </h4>
							<h6>{{ scheduleDate(event.event_start_date) }} - {{ scheduleDate(event.event_end_date) }}</h6>
						</div>
						<!-- Event Links -->
						<div class="row">
							<div class="col">
								<router-link :to="{ name: 'EventDetailView', params: {ID: event.eventsID}}" class="link-primary text-decoration-none me-5"><i class="bi bi-pencil-square"></i> view/edit</router-link>
								<router-link :to="{ name: 'ScheduleUploadView', params: {ID: event.eventsID}}" class="link-primary text-decoration-none"><i class="bi bi-upload"></i> schedule upload</router-link>
							</div>
						</div>
						<!-- /Event Links -->

						<!-- rooms -->
						<div class="row mt-3">
							<div class="col-12">
								<table class="table table-responsive border">
									<thead class="">
										<tr>
											<th class="text-white" colspan="2">Room</th>

										</tr>
									</thead>
									<tbody>
										<tr v-for="room in event.rooms" :key="room.event_roomsID">
											<td>
												<p class="card-title"><i class="bi bi-geo-alt-fill"></i> {{ room.room_name }}</p>
												<div class="upload-progress"></div>
											</td>
											<td>
												<router-link :to="{ name: 'EventRoomSchedule', params: {ID: room.event_roomsID}}" class="link-primary text-decoration-none"><i class="bi bi-calendar-date"></i> View Schedule</router-link>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<!-- /rooms -->
					</div>
				</div>
			</div>
		</div>
		<!-- /Event -->

	</div>
</template>

<script>
import eventService from "@/service/EventService";

export default {
	name: 'DashboardHome',
	title: 'SYNC Dashboard',
	data() {
		return {
			myGroupsEvents: []
		}
	},
	methods: {
		setMyGroupsEvents(){
			eventService.getAll().then((response) => {
				if (response.data.status === 200){
					this.myGroupsEvents = response.data.record;
				}
			}, (error) => {
				console.log(error);
			});
		},
		scheduleDate(date){
			let d = this.dayjs(date, "YYYY-MM-DD");
			return d.format("MMMM D, YYYY");
		}
	},
	mounted() {
		this.setMyGroupsEvents();
	}
}
</script>
<style lang="scss">

</style>
